import logo from './SPEWcast-01.png';
import youtube from './youtube.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a href="https://www.youtube.com/@SPEWcast"><img src={youtube} className="yt-logo" alt="logo" /></a>
      </header>
    </div>
  );
}

export default App;
